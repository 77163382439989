import { graphql, PageProps } from "gatsby";
import React from "react";
import "twin.macro";
import { SEO } from "../components";
import { BlankPageNoImage, LocationCard } from "../components/common";

const text =
  "Give us a call or drop us a line. We are here to help! With over a decade of experience we're ready and waiting to help, educate, inspire and most of all assist you.";

const LocationsPage: React.FC<PageProps> = ({ data: { allStrapiBranch } }) => {
  return (
    <>
      <SEO title="Get In Touch" />
      <BlankPageNoImage
        header="Get In Touch"
        intro={text}
        ariaLabel="Get In Touch"
      >
        <div tw="space-y-5 max-w-screen-xl mx-auto">
          {allStrapiBranch.edges.map(({ node }) => (
            <LocationCard key={node.locationName} branch={node} />
          ))}
        </div>
      </BlankPageNoImage>
    </>
  );
};

export default LocationsPage;

export const query = graphql`
  query locationsPageQuery {
    allStrapiBranch {
      edges {
        node {
          id
          slug
          address {
            addressCountry
            addressLocality
            addressRegion
            postalCode
            streetAddress
            override
          }
          booking_url
          cityOrCounty
          email
          googlePlacesId
          locationName
          openingHours {
            monday {
              closed
              closes
              opens
            }
            tuesday {
              closed
              closes
              opens
            }
            wednesday {
              closed
              closes
              opens
            }
            thursday {
              closed
              closes
              opens
            }
            friday {
              closed
              closes
              opens
            }
            saturday {
              closed
              closes
              opens
            }
            sunday {
              closed
              closes
              opens
            }
          }
          telephone
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
